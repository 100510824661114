export const CURRENT_ENVIRONMENT = "dev";

export const clientIds = {
    "dev" : "18067003379-mrp8rqqkdtaujm4bh44u9mfuaedb31qa.apps.googleusercontent.com",
    "prod" : "320683726531-r50lr31nrtf4p0i00gtj18llrr6lj0fi.apps.googleusercontent.com",
    "localhost": "18067003379-mrp8rqqkdtaujm4bh44u9mfuaedb31qa.apps.googleusercontent.com",
};

export const measurementIds = { //React GA
    "dev" : "UA-225352821-1",
    "prod" : "UA-225352821-2",
    "localhost" : "UA-225352821-1",
}

export const msClientIds = {
    "dev":"0cd04789-1830-4c11-93c2-ef6990a75adf", //"3c7b1d69-bfec-4a02-a8f5-95d3855b4f58", 
    "prod":"3f5aeeb0-042c-4953-95e2-eae7b3ffae01",
    "localhost":"3c7b1d69-bfec-4a02-a8f5-95d3855b4f58"
}

export const msTenantIds = {
    "dev":"5f9bacc0-ffe8-41f7-8d25-215d55cb0f96",
    "prod":"5f9bacc0-ffe8-41f7-8d25-215d55cb0f96",
    "localhost":"5f9bacc0-ffe8-41f7-8d25-215d55cb0f96",
}

export const redirectUri = {
    "dev":"https://dev-learning.wavicledata.com/", //"http://localhost:3000/",
    "prod":"https://learning.wavicledata.com/",
    "localhost":"http://localhost:3000/"
}